import React from "react";
import Typography from "@material-ui/core/Typography";

import Chip from "@material-ui/core/Chip";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import FaceIcon from "@material-ui/icons/Face";
import DoneIcon from "@material-ui/icons/Done";
import TableBA from "./components/TableBA";
import SurveyBA from "./components/SurveyBA";
import Button from "@material-ui/core/Button";
import DashboardService from "./services";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSurvey: false,
    };
  }

  componentDidMount() {
    this.fetchTable();
  }
  fetchTable = () => {
    DashboardService.fetchTable().then(
      (response) => {
        console.log(response.data);
      },
      (err) => {}
    );
  };
  callBackForShowingSurvey = () => {
    this.setState((prevState) => ({ showSurvey: !prevState.showSurvey }));
  };

  logout = () => {
    localStorage.removeItem("authToken");
    this.props.history.push("/login");
  };

  render() {
    return (
      <div>
        <DashboardHeader />
        <TableBA callBackForShowingSurvey={this.callBackForShowingSurvey} />
        <Button color="secondary" onClick={this.logout}>
          Logout
        </Button>
        <Dialog
          fullScreen
          open={this.state.showSurvey}
          maxWidth="xs"
          fullWidth={true}
          TransitionComponent={Transition}
          onClose={this.callBackForShowingSurvey}
        >
          <SurveyBA />
        </Dialog>
      </div>
    );
  }
}

function DashboardHeader() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        padding: 20,
        justifyContent: "space-between",
        alignItems: "center",
        paddingTop: 30,
      }}
    >
      <DateContainer />
      <Chip
        icon={<FaceIcon />}
        label="Visit Summary"
        clickable
        color="primary"
        deleteIcon={<DoneIcon />}
        variant="outlined"
      />
    </div>
  );
}

function DateContainer() {
  var days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  var d = new Date();
  var dayName = days[d.getDay()];
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Typography variant="subtitle2" align="left">
        {dayName} {d.toLocaleDateString(undefined, options)}
      </Typography>
      <Typography variant="subtitle2" align="left" style={{ color: "brown" }}>
        Username
      </Typography>
    </div>
  );
}
