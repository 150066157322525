import api from "../../../utils/network";

const DashboardService = {
  fetchTable: function () {
    return api.get("/user/summary");
  },

  verifyToken: function (data) {
    return api.post("/api/token/verify", data);
  },
  getUserDetail: function () {
    return api.get("/core/api/v1/me");
  },
};

export default DashboardService;
