import "./App.css";
import React from "react";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";

import Container from "@material-ui/core/Container";

import { NotificationContainer } from "react-notifications";
import { PrivateRoute } from "./utils/privateRoute";
import Dashboard from "./pages/Dashboard";
import LoginPage from "./pages/Login";

import "react-notifications/lib/notifications.css";

function App() {
  return (
    <React.Fragment>
      <Container
        maxWidth="sm"
        style={{ background: "#fafafa", height: "100vh", padding: 0 }}
      >
        <NotificationContainer />
        <Router>
          <Switch>
            <Route path="/login" exact component={LoginPage} />
            <PrivateRoute path="/" exact component={Dashboard} />
            <Route component={() => <h1>Not Found</h1>} />
          </Switch>
        </Router>
      </Container>
    </React.Fragment>
  );
}

export default App;
