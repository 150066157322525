import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Notifications from "../../utils/notifications";
import CircularProgress from "@material-ui/core/CircularProgress";

import authenticationService from "./services";

function Copyright() {
  return (
    <Typography
      variant="button"
      align="center"
      style={{ color: "#ff000091", fontWeight: "bold" }}
    >
      Smoking Kills
    </Typography>
  );
}

function DateContainer() {
  var days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  var d = new Date();
  var dayName = days[d.getDay()];
  return (
    <div>
      <Typography variant="caption">
        {dayName} {d.toLocaleDateString(undefined, options)}
      </Typography>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  timeContainer: {
    fontFamily: "monospace",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
    padding: 16,
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "red",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    background: "#4f81bd",
  },
}));

export default function Login(props) {
  const classes = useStyles();
  const [username, setUserName] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [fetchingToken, setFetchingToken] = React.useState(false);

  const onChangeUserName = (event) => {
    setUserName(event.target.value);
  };

  const onChangePassword = (event) => {
    setPassword(event.target.value);
  };

  const handleLogin = () => {
    const data = {
      username: username,
      password: password,
    };
    console.log("het");
    setFetchingToken(true);
    authenticationService.login(data).then(
      (response) => {
        console.log(response.data);
        localStorage.setItem("authToken", response.data.access);
        Notifications.success("Login Successfull");

        props.history.push("/");
      },
      (err) => {
        console.log(err);
        setFetchingToken(false);
        Notifications.error(
          "Login failed ! Please provide correct username and password"
        );
      }
    );
  };

  return (
    <div className={classes.container}>
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>

        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="username"
          label="Username"
          name="username"
          onChange={onChangeUserName}
          autoFocus
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          onChange={onChangePassword}
        />

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handleLogin}
          disable={fetchingToken}
        >
          {!fetchingToken ? (
            "Log in"
          ) : (
            <CircularProgress style={{ color: "white" }} size={20} />
          )}
        </Button>
      </div>
      <Box mt={15}>
        <DateContainer />
      </Box>
      <Box>
        <Copyright />
      </Box>
    </div>
  );
}
