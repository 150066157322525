import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";

class SurveyBA extends React.Component {
  render() {
    return (
      <div>
        <CustomCheckBox />
        <CustomSelect
          label="Well groomed ?"
          options={["Yes", "No"]}
          values={[true, false]}
        />
        <CustomSelect
          label="All the tools available ?"
          options={["Yes", "No"]}
          values={[true, false]}
        />
        <CustomSelect
          label="Contact Quality"
          options={["Good", "Needs improvement", "Bad"]}
          values={["GD", "NI", "BD"]}
        />
        <CustomSelect
          label="Brand Message check"
          options={["Recalled", "Not Recalled"]}
          values={["RC", "NR"]}
        />
        <CustomSelect
          label="Field Coaching"
          options={["Done", "Not required"]}
          values={["DN", "NR"]}
        />

        <form noValidate autoComplete="off">
          <TextField
            id="comment"
            label="Comment here"
            variant="outlined"
            multiline
            rowsMax={2}
            style={{
              width: "90%",
              marginTop: 20,
            }}
          />
        </form>
      </div>
    );
  }
}

class CustomCheckBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
    };
  }
  handleChange = (event) => {
    console.log(event.target.checked);
    this.setState({ checked: event.target.checked });
  };

  render() {
    return (
      <FormControlLabel
        control={<Checkbox color="primary" />}
        label="Present on time"
        labelPlacement="start"
        checked={this.state.checked}
        onChange={this.handleChange}
        style={{ transform: "scale(1.5)", marginTop: 20, marginBottom: 10 }}
      />
    );
  }
}

class CustomSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
    };
  }

  handleChange = (event) => {
    console.log(event.target.value);
    this.setState({ value: event.target.value });
  };
  render() {
    return (
      <FormControl variant="filled" style={{ width: "90vw", margin: 10 }}>
        <InputLabel
          id="select-filled-label"
          style={{ fontSize: 18, color: "#4f81bd" }}
        >
          {this.props.label}
        </InputLabel>
        <Select
          labelId={this.props.label}
          id={this.props.label}
          value={this.state.value}
          onChange={this.handleChange}
          style={{ textAlign: "left" }}
        >
          <MenuItem
            disabled
            style={{
              background: "none",
              color: "#4f81bd",
              fontWeight: "bold",
              opacity: 1,
            }}
          >
            {this.props.label}
          </MenuItem>
          {this.props.options.map((item, index) => {
            return (
              <MenuItem
                key={index}
                value={this.props.values[index]}
                style={{
                  background: "whitesmoke",
                  borderRadius: 20,
                  margin: 5,
                }}
              >
                {item}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  }
}

export default SurveyBA;
