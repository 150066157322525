import { NotificationManager } from "react-notifications";
import "./index.css";

const Notifications = {
  success: function (message) {
    NotificationManager.success(message);
    return;
  },
  error: function (message) {
    NotificationManager.error(message);
    return;
  },
};

export default Notifications;
