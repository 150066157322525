import axios from "axios";

const api = axios.create({
  //baseURL: process.env.REACT_APP_API_URL,
  baseURL: "https://dev-supervisor-microsite.qikcheck.com",
});

api.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    var token = localStorage.getItem("authToken");
    if (token) {
      config.headers.Authorization = "Bearer " + token;
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

export default api;
